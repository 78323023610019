import addDays from 'date-fns/addDays'
import subMilliseconds from 'date-fns/subMilliseconds'

const resourceMap = {
  users: {
    resource: 'api/v1/rest/users',
  },
  'region-zone': {
    resource: 'api/v1/service/region-zone',
  },
  'tide-table': {
    resource: 'api/v1/service/tide-table',
  },
  vhf: {
    resource: 'api/v1/service/vhf',
  },
  'life-jackets': {
    resource: 'api/v1/service/life-jackets',
  },
  'marine-reserve': {
    resource: 'api/v1/service/marine-reserve',
  },
  'mammal-area': {
    resource: 'api/v1/service/mammal-area',
  },
  mataitai: {
    resource: 'api/v1/service/mataitai',
  },
  taiapure: {
    resource: 'api/v1/service/taiapure',
  },
  bylaw: {
    resource: 'api/v1/rest/bylaw',
  },
  notice: {
    resource: 'api/v1/rest/notice',
  },
  cms: {
    resource: 'api/v1/service/cms',
  },
  category: {
    resource: 'api/v1/rest/category',
  },
  'bylaw-area': {
    resource: 'api/v1/service/bylaw-area',
  },
  'boat-ramp': {
    resource: 'api/v1/service/boat-ramp',
  },
  'gallery-image': {
    resource: 'api/v1/rest/gallery-image',
  },
  hazard: {
    resource: 'api/v1/service/hazard',
  },
  page: {
    resource: 'api/v1/rest/page',
  },
  'page-block': {
    resource: 'api/v1/rest/page-block',
  },
  marker: {
    resource: 'api/v1/rest/marker',
  },
  shape: {
    resource: 'api/v1/rest/shape',
  },
  'app-version': {
    resource: 'api/v1/rest/app-version',
  },
  tide: {
    resource: 'api/v1/rest/tide',
  },
  settings: {
    resource: 'api/v1/rest/settings',
  },
}

const mutateLikeFilters = params => {
  // clone deep nested object
  const paramsWithFilter = JSON.parse(JSON.stringify(params))

  if ('filter' in paramsWithFilter) {
    for (let fieldName in paramsWithFilter.filter) {
      const filter = paramsWithFilter.filter[fieldName]
      if (typeof filter === 'object' && '$like' in filter) {
        const filterValue = filter['$like']
        paramsWithFilter.filter[fieldName]['$like'] = `%${filterValue}%`
      }
    }
  }

  return paramsWithFilter
}

const mutateDateFilters = params => {
  // clone deep nested object
  const paramsWithFilter = JSON.parse(JSON.stringify(params))

  if ('filter' in paramsWithFilter) {
    for (let fieldName in paramsWithFilter.filter) {
      const filter = paramsWithFilter.filter[fieldName]
      if (
        ['updated_at', 'created_at'].indexOf(fieldName) !== -1 &&
        '$lte' in filter
      ) {
        let filterValue = new Date(filter['$lte'])
        filterValue = addDays(filterValue, 1)
        filterValue = subMilliseconds(filterValue, 1)

        paramsWithFilter.filter[fieldName]['$lte'] = filterValue.toISOString()
      }
    }
  }

  return paramsWithFilter
}

export const mappedRequests = dataProvider => (type, resource, params) => {
  if (resource in resourceMap) {
    const newValues = resourceMap[resource]
    resource = newValues.resource
    params = Object.assign(params, newValues.params)
  }

  let result
  // we don't want to mutate the params for any updated, delete or create
  // the object clone will not work if a file is attached to the params object
  if (type === 'GET_LIST') {
    let paramsWithFilters = mutateLikeFilters(params)
    paramsWithFilters = mutateDateFilters(paramsWithFilters)
    result = dataProvider(type, resource, paramsWithFilters)
  } else {
    result = dataProvider(type, resource, params)
  }

  return result
}
