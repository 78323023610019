import React, {Fragment, useState} from 'react'
import {
  Create,
  required,
  Button,
  SaveButton,
  SimpleForm,
  TextInput,
  FileInput,
  FileField,
} from 'react-admin'
import IconContentAdd from '@material-ui/icons/Add'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

export const GalleryImageUpload = ({location, basePath, record}) => {
  const [showDialog, setShowDialog] = useState(false)

  return (
    <Fragment>
      <Button onClick={() => setShowDialog(true)} label="Upload">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-label="Upload image">
        <DialogTitle>Upload image</DialogTitle>
        <DialogContent>
          <Create
            resource="gallery-image"
            location={location}
            basePath={basePath}>
            <SimpleForm
              resource="gallery-image"
              toolbar={
                <FormToolBar onCancelClick={() => setShowDialog(false)} />
              }
              initialValues={{poi_id: record.id}}>
              <TextInput source="title" validate={required()} autoFocus />
              <FileInput
                source="file_id"
                validate={required()}
                label="Image"
                accept="image/*">
                <FileField source="src" title="filename" />
              </FileInput>
            </SimpleForm>
          </Create>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

const FormToolBar = ({onCancelClick, handleSubmitWithRedirect}) => {
  return (
    <DialogActions>
      <SaveButton
        redirect={false}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        submitOnEnter={false}
      />
      <Button label="ra.action.cancel" onClick={onCancelClick}>
        <IconCancel />
      </Button>
    </DialogActions>
  )
}
