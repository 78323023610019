import React from 'react'
import {Create, SaveButton, SimpleForm} from 'react-admin'
import TextFieldsIcon from '@material-ui/icons/TextFields'
import ImageIcon from '@material-ui/icons/Image'
import MovieIcon from '@material-ui/icons/Movie'

export const PageBlockAddButtons = ({resource, basePath, location, id}) => (
  <>
    <Create
      basePath={basePath}
      location={location}
      resource="page-block"
      title={<></>}>
      <SimpleForm
        redirect={false}
        resource={resource}
        toolbar={
          <AddBlockButton
            labelText="Add A Text Block"
            icon={<TextFieldsIcon />}
          />
        }
        initialValues={{
          page_id: id,
          type: 'rich_text',
          data: '{}',
        }}>
        <></>
      </SimpleForm>
    </Create>
    <Create
      basePath={basePath}
      location={location}
      resource="page-block"
      title={<></>}>
      <SimpleForm
        redirect={false}
        resource={resource}
        toolbar={
          <AddBlockButton labelText="Add A Video Block" icon={<MovieIcon />} />
        }
        initialValues={{
          page_id: id,
          type: 'video',
          data: '{}',
        }}>
        <></>
      </SimpleForm>
    </Create>
    <Create
      basePath={basePath}
      location={location}
      resource="page-block"
      title={<></>}>
      <SimpleForm
        redirect={false}
        resource={resource}
        toolbar={
          <AddBlockButton labelText="Add A Image Block" icon={<ImageIcon />} />
        }
        initialValues={{
          page_id: id,
          type: 'image',
          data: '{}',
        }}>
        <></>
      </SimpleForm>
    </Create>
  </>
)

const AddBlockButton = ({labelText, icon, handleSubmitWithRedirect}) => {
  const style = {
    marginBottom: '10px',
    width: '100%',
    textAlign: 'center',
  }

  return (
    <div style={style}>
      <SaveButton
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        variant="contained"
        color="primary"
        label={labelText}
        submitOnEnter={false}>
        {icon}
      </SaveButton>
    </div>
  )
}
