import {Box} from '@material-ui/core'
import React from 'react'
import {Edit, SimpleForm, TextInput} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import LeafletMapMarkerField from '../../components/LeafletMap/LeafletMapMarkerField'
import {RichTextInput} from '../../components/RichTextInput'
import {maxLength, required} from '../../lib/common-validators'

const LifeJacketsTitle = ({record}) => <Box>{record ? record.title : ''}</Box>

export const LifeJacketsEdit = props => (
  <Edit undoable={false} {...props} title={<LifeJacketsTitle />}>
    <SimpleForm>
      <HeaderWithPreviousButton title="Life Jacket Edition" />
      <TextInput
        source="title"
        label="Title"
        fullWidth={true}
        validate={[required, maxLength(255)]}
      />
      <RichTextInput source="description" />
      <LeafletMapMarkerField />
    </SimpleForm>
  </Edit>
)
