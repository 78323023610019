export const feathersAuthProvider = (client, options = {}) => {
  const defaultOptions = {
    debug: false,
  }
  authProvider.client = client
  authProvider.options = {...defaultOptions, ...options}
  return authProvider
}

const storageKey = 'ra-feathers-token'
const emailKey = 'ra-feathers-email'

const authProvider = {
  /**
   * feathers js client
   */
  client: null,

  /**
   * login
   *
   * @param {*} params
   */
  login: async function(params) {
    const {username, password} = params
    const authCredentials = {
      strategy: 'local',
      email: username,
      password: password,
    }
    const response = await this.client.authenticate(authCredentials)
    if (response.accessToken) {
      localStorage.setItem(emailKey, username)
      return Promise.resolve()
    } else {
      return Promise.reject()
    }
  },

  /**
   * logout
   */
  logout: function() {
    localStorage.removeItem(storageKey)
    localStorage.removeItem(emailKey)
    return this.client.logout()
  },

  /**
   * verify if the current user is still logged in
   */
  checkAuth: function() {
    return localStorage.getItem(storageKey)
      ? Promise.resolve()
      : Promise.reject(new Error('Authentication check failed'))
  },

  /**
   * process error accordingly
   *
   * @param {*} error
   */
  checkError: function(error) {
    const status = error.status
    if (status === 401 || status === 403) {
      localStorage.removeItem(storageKey)
      localStorage.removeItem(emailKey)
      return Promise.reject()
    }
    return Promise.resolve()
  },

  /**
   * custom role determination in order to give
   * access to certain resources
   */
  getPermissions: function() {
    const email = localStorage.getItem(emailKey)
    if (!email) {
      return Promise.reject()
    }
    let role = 'user'
    if (email.includes('@waikatoregion.govt.nz')) {
      role = 'admin'
    } else if (email.includes('@we-are-mea.com')) {
      role = 'admin'
    } else if (email === 'admin') {
      role = 'admin'
    }

    return Promise.resolve(role)
  },
}
