import {Box} from '@material-ui/core'
import React from 'react'
import {
  Datagrid,
  DateField,
  Edit,
  FileField,
  FileInput,
  Pagination,
  ReferenceManyField,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import LeafletMapMarkerField from '../../components/LeafletMap/LeafletMapMarkerField'
import {maxLength, required} from '../../lib/common-validators'

const TideTitle = ({record}) => <Box>{record ? record.title : ''}</Box>

export const TideEdit = props => (
  <Edit undoable={false} {...props} title={<TideTitle />}>
    <SimpleForm>
      <HeaderWithPreviousButton title="Tide Table Edition" />
      <TextInput
        source="title"
        validate={[required, maxLength(255)]}
        fullWidth={true}
      />
      <LeafletMapMarkerField />
      <FileInput source="csv_file" label="CSV" accept="text/csv">
        <FileField source="src" title="filename" />
      </FileInput>
      <ReferenceManyField
        pagination={<Pagination />}
        sort={{field: 'date', order: 'ASC'}}
        label="tides"
        reference="tide"
        target="place_id"
        fullWidth={true}>
        <Datagrid>
          <DateField source="created_at" />
          <DateField source="date" />
          <TextField source="high_time_1" />
          <TextField source="high_level_1" />
          <TextField source="low_time_1" />
          <TextField source="low_level_1" />

          <TextField source="high_time_2" />
          <TextField source="high_level_2" />
          <TextField source="low_time_2" />
          <TextField source="low_level_2" />

          <TextField source="high_time_3" />
          <TextField source="high_level_3" />
          <TextField source="low_time_3" />
          <TextField source="low_level_3" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleForm>
  </Edit>
)
