import {Box} from '@material-ui/core'
import React from 'react'

const Flex = ({children, ...props}) => {
  return (
    <Box
      display="Flex"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      {...props}>
      {children}
    </Box>
  )
}

export default Flex
