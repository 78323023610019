import React from 'react'
import {PageBlockRichTextInput} from './PageBlockRichTextInput'
import {PageBlockImageInput} from './PageBlockImageInput'
import {PageBlockVideoInput} from './PageBlockVideoInput'

export const PageBlocksInput = props => {
  const {ids, data, resource, location} = props
  const basePath = location.pathname

  if (!ids) return null
  return ids
    .sort((a, b) => a - b)
    .map(id => {
      let component = null
      const record = data[id]
      const type = record.type
      switch (type) {
        case 'rich_text':
          component = (
            <div style={{margin: 15}} key={id}>
              <PageBlockRichTextInput
                basePath={basePath}
                type={type}
                record={record}
                resource={resource}
              />
            </div>
          )
          break
        case 'image':
          component = (
            <div style={{margin: 15}} key={id}>
              <PageBlockImageInput
                basePath={basePath}
                type={type}
                record={record}
                resource={resource}
              />
            </div>
          )
          break
        case 'video':
          component = (
            <div style={{margin: 15}} key={id}>
              <PageBlockVideoInput
                basePath={basePath}
                type={type}
                record={record}
                resource={resource}
              />
            </div>
          )
          break
        default:
      }
      return component
    })
}
