import {Box} from '@material-ui/core'
import React from 'react'
import {Edit, SimpleForm, TextInput} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import LeafletMapMarkerField from '../../components/LeafletMap/LeafletMapMarkerField'
import {maxLength, required} from '../../lib/common-validators'

const VhfTitle = ({record}) => <Box>{record ? record.title : ''}</Box>

export const VhfEdit = props => (
  <Edit undoable={false} {...props} title={<VhfTitle />}>
    <SimpleForm>
      <HeaderWithPreviousButton title="VHF Edition" />
      <TextInput
        source="title"
        validate={[required, maxLength(255)]}
        fullWidth={true}
      />
      <TextInput
        source="description"
        label="Description"
        validate={required}
        multiline={true}
        fullWidth={true}
      />
      <LeafletMapMarkerField />
    </SimpleForm>
  </Edit>
)
