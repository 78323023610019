import {Box} from '@material-ui/core'
import React from 'react'
import {Edit, SimpleForm, TextInput} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import {ImageInputWithPreview} from '../../components/ImageInputWithPreview'
import LeafletMapMarkerShapeField from '../../components/LeafletMap/LeafletMapMarkerShapeField'
import ShapeReferenceInput from '../../components/ShapeReferenceInput'
import {maxLength, required} from '../../lib/common-validators'

const MataitaiTitle = ({record}) => <Box>{record ? record.title : ''}</Box>

export const MataitaiEdit = props => (
  <Edit undoable={false} {...props} title={<MataitaiTitle />}>
    <SimpleForm>
      <HeaderWithPreviousButton title="Mātaitai Edition" />
      <TextInput
        source="title"
        validate={[required, maxLength(255)]}
        fullWidth={true}
      />
      <TextInput
        fullWidth
        multiline
        source="description"
        label="Description"
        validate={required}
      />
      <ShapeReferenceInput />
      <LeafletMapMarkerShapeField />
      <ImageInputWithPreview source="file_id" />
    </SimpleForm>
  </Edit>
)
