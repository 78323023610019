import {Box} from '@material-ui/core'
import React from 'react'
import {BooleanInput, Edit, SimpleForm, TextInput} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import {maxLength, required} from '../../lib/common-validators'

const AppVersionTitle = ({record}) => <Box>{record ? record.title : ''}</Box>

export const SettingsEdit = props => (
  <Edit undoable={false} {...props} title={<AppVersionTitle />}>
    <SimpleForm>
      <HeaderWithPreviousButton title="Setting edition" />
      <TextInput
        source="name"
        label="name"
        validate={[required, maxLength(255)]}
      />
      <TextInput source="value" multiline />
      <TextInput source="description" multiline />
      <BooleanInput source="enable" />
    </SimpleForm>
  </Edit>
)
