import {Box, InputLabel} from '@material-ui/core'
import Center from '@material-ui/icons/CenterFocusStrongOutlined'
import center from '@turf/center'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button, NumberInput, useQuery} from 'react-admin'
import {useForm, useFormState} from 'react-final-form'
import {numberRequired} from '../../lib/common-validators'
import {cleanGeoJSONLegacy} from '../../utils/helpers'
import {Flex} from '../Layout'
import LoaderOverlay from '../LoaderOverlay'
import LeafletMap from './LeafletMap'

const LeafletMapMarkerShapeField = () => {
  return (
    <Box marginTop={2}>
      <InputLabel>Marker position</InputLabel>

      <Flex justifyContent="start" alignItems="start">
        <Box mr={3} width={300}>
          <NumberInput
            source="lat"
            label="Latitude"
            validate={numberRequired}
            fullWidth={true}
          />
        </Box>
        <Box width={300}>
          <NumberInput
            source="lng"
            label="Longitude"
            validate={numberRequired}
            fullWidth={true}
          />
        </Box>
      </Flex>

      <LeafletMapMarkerShapeContainer />
    </Box>
  )
}

export default LeafletMapMarkerShapeField

const LeafletMapMarkerShapeContainer = ({height = 500}) => {
  const {
    values: {lat = 0, lng = 0, shape_id},
  } = useFormState()
  const {change} = useForm()

  const [GeoJSON, setGeoJSON] = useState(null)
  const [marker, setMarker] = useState()

  useEffect(() => {
    if (lat && lng) setMarker([lat, lng])
  }, [lat, lng])

  const {data: shape, loading} = useQuery({
    type: 'getOne',
    resource: 'shape',
    payload: {id: shape_id},
  })

  useEffect(() => {
    try {
      const data = shape?.coords
        ? cleanGeoJSONLegacy(JSON.parse(shape?.coords))
        : null

      setGeoJSON(data)
    } catch (e) {
      console.info('Not a valid json', e)
    }
  }, [shape])

  const centerShape = useMemo(() => {
    if (GeoJSON) {
      const {
        geometry: {coordinates},
      } = center(GeoJSON)

      const [lng, lat] = coordinates

      return {lat, lng}
    }
  }, [GeoJSON])

  useEffect(() => {
    if (GeoJSON && !lat && !lng) {
      const {lat, lng} = centerShape

      setMarker([lat, lng])

      change('lat', lat)
      change('lng', lng)
    }
  }, [centerShape, setMarker, GeoJSON, change, shape_id, lat, lng])

  const onUpdatePosition = useCallback(() => {
    const {lat, lng} = centerShape

    change('lat', lat)
    change('lng', lng)
  }, [centerShape, change])

  const isShapeCenterMarkerPosition = useMemo(() => {
    if (!lat || !lng || !centerShape) {
      return false
    }
    const {lat: latShape, lng: lngShape} = centerShape

    return parseFloat(lat) === latShape && parseFloat(lng) === lngShape
      ? true
      : false
  }, [centerShape, lat, lng])

  return (
    <>
      {shape_id && !isShapeCenterMarkerPosition && !loading && (
        <Box mt={1}>
          <Button
            onClick={onUpdatePosition}
            label="Update position based on shape">
            <Center />
          </Button>
        </Box>
      )}

      <Box position="relative">
        <Box height={height} mt={2}>
          <LeafletMap
            GeoJSON={GeoJSON}
            zoom={marker ? 8 : 5}
            marker={marker}
            center={marker}
          />
        </Box>

        <LoaderOverlay isVisible={loading} />
      </Box>
    </>
  )
}
