import {Box} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import {useTheme} from '@material-ui/core/styles'
import IconContentAdd from '@material-ui/icons/Add'
import IconCancel from '@material-ui/icons/Cancel'
import IconSave from '@material-ui/icons/Save'
import React from 'react'
import {Button, TextInput, useCreate, useNotify} from 'react-admin'
import {Field, Form, useForm} from 'react-final-form'
import {useBoolean} from 'react-use'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import {Flex} from '../../components/Layout'
import LoaderOverlay from '../../components/LoaderOverlay'
import ShapeField from '../../components/ShapeField'
import {useButtonStyles} from '../../hooks/useButtonStyles'
import {required} from '../../lib/common-validators'

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
}

const ShapeQuickCreation = () => {
  const [isOpen, toggleIsOpen] = useBoolean(false)

  const {
    palette: {grey},
  } = useTheme()
  const {saveButton} = useButtonStyles()

  const [create, {loading}] = useCreate('shape')
  const form = useForm()
  const notify = useNotify()

  const handleSubmit = async values => {
    await create(
      {payload: {data: values}},
      {
        onSuccess: ({data}) => {
          notify('Shape successfully created', 'success')
          form.change('shape_id', data.id)
          toggleIsOpen(false)
        },
        onFailure: ({error}) => {
          notify('Something went wrong while creating the shape.', 'error')
        },
      },
    )
  }

  return (
    <>
      <Button onClick={toggleIsOpen} label="ra.action.create">
        <IconContentAdd />
      </Button>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={toggleIsOpen}
        aria-label="Create a shape">
        <DialogContent>
          <Form
            onSubmit={handleSubmit}
            subscription={defaultSubscription}
            keepDirtyOnReinitialize
            render={({
              handleSubmit,
              _form,
              _submitting,
              _pristine,
              _values,
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}>
                <Box>
                  <HeaderWithPreviousButton
                    title="Shape Creation"
                    isPreviousVisible={false}
                  />
                  <Field name="name">
                    {props => (
                      <TextInput
                        label={'Name'}
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        validate={required}
                        fullWidth={true}
                      />
                    )}
                  </Field>
                  <ShapeField source="coords" />
                </Box>

                <DialogActions>
                  <Flex
                    border={0}
                    borderTop={1}
                    borderColor={grey[200]}
                    width="100%"
                    justifyContent="end"
                    py={3}>
                    <Box marginRight={3}>
                      <Button
                        label="ra.action.save"
                        className={saveButton}
                        type="submit">
                        <IconSave />
                      </Button>
                    </Box>
                    <Button label="ra.action.cancel" onClick={toggleIsOpen}>
                      <IconCancel />
                    </Button>
                  </Flex>
                </DialogActions>
              </form>
            )}
          />
          <LoaderOverlay isVisible={loading} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ShapeQuickCreation
