import 'ace-builds'
import 'ace-builds/webpack-resolver'
import React, {createContext} from 'react'
import {Field} from 'react-final-form'
import {useBoolean} from 'react-use'
import {isValidGeoJSON} from '../lib/common-validators'
import AceEditorJson from './AceEditor/AceEditorJson'
import FieldErrors from './FieldErrors'
import {Flex} from './Layout'
import LeafletMapContainer from './LeafletMap/LeafletMapContainer'

export const ShapeContext = createContext()

const ShapeField = ({source, record = {}, height = 720}) => {
  const [isSimplifyEnabled, toggleIsSimplifyEnabled] = useBoolean(false)

  return (
    <Field name={source} validate={isValidGeoJSON}>
      {({input, meta}) => {
        return (
          <ShapeContext.Provider
            value={{isSimplifyEnabled, toggleIsSimplifyEnabled}}>
            <Flex width="100%" alignItems="stretch" height={height} mt={2}>
              <AceEditorJson
                isFieldInvalid={meta.error && meta.touched}
                source={source}
                record={record}
                input={input}
                width="50%"
              />
              <LeafletMapContainer record={record} source={source} />
            </Flex>
            <FieldErrors meta={meta} />
          </ShapeContext.Provider>
        )
      }}
    </Field>
  )
}

export default ShapeField
