import React from 'react'
import {
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin'
import {ListActions} from '../../components/ListActions'

const MammalAreasFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by title" source="title[$like]" alwaysOn />
    <TextInput
      label="Search by description"
      source="description[$like]"
      alwaysOn
    />
  </Filter>
)

export const MammalAreasList = props => (
  <List
    {...props}
    title="Mammal Areas List"
    actions={<ListActions />}
    bulkActionButtons={false}
    filters={<MammalAreasFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <ReferenceField source="shape_id" reference="shape" label="Shape id">
        <TextField source="id" />
      </ReferenceField>
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)
