import React from 'react'
import {SimpleForm, TextInput, regex, Edit} from 'react-admin'
import {ImageInputWithPreview} from './ImageInputWithPreview'

export const PageBlockImageInput = ({record, location, basePath}) => {
  record.link = ''
  if (record.data) {
    const data = JSON.parse(record.data)
    if (data.link) {
      record.link = data.link
    }
  }

  return (
    <Edit
      undoable={false}
      resource="page-block"
      record={record}
      location={location}
      basePath={basePath}
      id={record.id}
      title={<></>}>
      <SimpleForm>
        <TextInput
          id={`image-link-input-${record.id}`}
          multiline
          source="link"
          label="Link"
          validate={regex(/^https?:\/\//, "Must be a URL starting with 'http'")}
        />
        <ImageInputWithPreview
          id={`image-video-preview-input-${record.id}`}
          source="file_id"
        />
      </SimpleForm>
    </Edit>
  )
}
