import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  ReferenceInput,
  TextInput,
  SelectInput,
  Filter,
  NumberField,
} from 'react-admin'
import {ListActions} from '../../components/ListActions'
import {PageIcon} from '../../components/PageIcon'

// TODO: can this be made into a wrapper component?
// TODO: PR for react-admin to fix the behaviour of ReferenceField to negate the need for this
const PagesCategory = props => {
  if (props.record && props.record.category_id) {
    return (
      <ReferenceField source="category_id" reference="category" {...props}>
        <TextField source="title" />
      </ReferenceField>
    )
  } else {
    return <p>-</p>
  }
}

const PagesFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="title[$like]" alwaysOn />
    <ReferenceInput
      label="Category"
      source="category_id"
      reference="category"
      alwaysOn
      allowEmpty>
      <SelectInput optionText="title" />
    </ReferenceInput>
  </Filter>
)

export const PagesList = props => {
  return (
    <List
      {...props}
      actions={<ListActions />}
      bulkActionButtons={false}
      filters={<PagesFilter />}>
      <Datagrid>
        <TextField source="id" />
        <PageIcon source="icon" />
        <TextField source="title" />
        <PagesCategory label="Category" />
        <NumberField source="order" />
        <EditButton />
      </Datagrid>
    </List>
  )
}
