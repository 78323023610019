import {Box} from '@material-ui/core'
import React from 'react'
import {BooleanInput, Edit, SimpleForm, TextInput} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import {maxLength, required} from '../../lib/common-validators'

const AppVersionTitle = ({record}) => <Box>{record ? record.title : ''}</Box>

export const AppVersionEdit = props => (
  <Edit undoable={false} {...props} title={<AppVersionTitle />}>
    <SimpleForm>
      <HeaderWithPreviousButton title="App Version Edition" />
      <TextInput
        source="version"
        label="Version"
        validate={[required, maxLength(255)]}
      />
      <BooleanInput source="force_update" />
    </SimpleForm>
  </Edit>
)
