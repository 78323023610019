import React from 'react'

export const FakePasswordField = () => (
  <input
    type="password"
    name="fake-password"
    autoComplete="new-password"
    tabIndex="-1"
    style={{opacity: 0, float: 'left', border: 'none', height: '0', width: '0'}}
  />
)
