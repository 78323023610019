import React from 'react'
import {Create, FileField, FileInput, SimpleForm} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import {required} from '../../lib/common-validators'

export const TaiapureCreate = props => (
  <Create {...props} title="Upload Taiāpure Shapefile">
    <SimpleForm>
      <HeaderWithPreviousButton title="Taiāpure Creation" />

      <FileInput
        source="shp_file"
        label="Shapefile (.zip)"
        accept="application/zip, application/x-zip-compressed"
        validate={required}>
        <FileField source="src" title="filename" />
      </FileInput>
    </SimpleForm>
  </Create>
)
