import {validateGeoJSON} from '../utils/helpers'

export const required = value => {
  if (Array.isArray(value)) {
    return value.length ? undefined : 'This is field is missing'
  }
  return value || typeof value === 'number'
    ? undefined
    : 'This is field is missing'
}

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

export const numberRequired = value =>
  value === undefined || value === null || (value && isNaN(Number(value)))
    ? 'Must be a number'
    : undefined

export const isValidGeoJSON = value => {
  try {
    JSON.parse(value)
  } catch {
    return 'Not a valid JSON'
  }
  const {errors} = validateGeoJSON(value)
  return errors.length > 0 ? 'GeoJSON format is invalid' : undefined
}
