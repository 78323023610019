import React from 'react'
import {Create, SimpleForm, TextInput} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import LeafletMapMarkerField from '../../components/LeafletMap/LeafletMapMarkerField'
import {RichTextInput} from '../../components/RichTextInput'
import {maxLength, required} from '../../lib/common-validators'

export const LifeJacketsCreate = props => (
  <Create {...props} title="Create LifeJackets Channel">
    <SimpleForm>
      <HeaderWithPreviousButton title="Life Jacket Creation" />
      <TextInput
        source="title"
        label="Title"
        fullWidth={true}
        validate={[required, maxLength(255)]}
      />
      <RichTextInput source="description" />
      <LeafletMapMarkerField />
    </SimpleForm>
  </Create>
)
