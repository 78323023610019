import React from 'react'
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin'
import {ListActions} from '../../components/ListActions'

const UserFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by email" source="email[$like]" alwaysOn />
  </Filter>
)

export const UsersList = props => (
  <List
    {...props}
    actions={<ListActions />}
    bulkActionButtons={false}
    filters={<UserFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="email" />
      <EditButton />
    </Datagrid>
  </List>
)
