import React from 'react'
import {Create, SimpleForm, TextInput} from 'react-admin'
import {FakePasswordField} from '../../components/FakePasswordField'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'

export const UserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <HeaderWithPreviousButton title="User Creation" />
      <FakePasswordField />
      <TextInput source="email" label="Email" type="email" />
      <TextInput source="password" label="Password" type="password" />
    </SimpleForm>
  </Create>
)
