import {hint} from '@mapbox/geojsonhint'
import rewind from '@turf/rewind'

/**
 * @param {object} value
 * @returns {object}
 */
export const cleanGeoJSONLegacy = data => {
  // Remove data.properties that has been applied to some shape
  // as it's a bad GeoJSON format
  if (data.properties) {
    delete data.properties
  }

  // We need to rewind the GeoJSON in case it wouldn't respect the "Right Hand Rule"
  // https://datatracker.ietf.org/doc/html/rfc7946#section-3.1.6
  return rewind(data)
}

/**
 * @param {string} value
 * @returns {object} {errors: array}
 */
export const validateGeoJSON = value => {
  const errors = hint(value, {
    precisionWarning: false,
  })
  return {errors}
}

/**
 * @param {string} value
 * @returns {string}
 */
export const beautifyJSON = value => {
  try {
    return JSON.stringify(JSON.parse(value), null, 4)
  } catch (error) {
    console.info('Not a valid JSON format to beautify: ', error.message)
  }
  return value
}

/**
 * @param {string} value
 * @returns {string}
 */
export const minifyJSON = value => {
  try {
    return JSON.stringify(JSON.parse(value))
  } catch (error) {
    console.info('Not a valid JSON format to minify: ', error.message)
  }
  return value
}

/**
 * @param {string} value
 * @param {boolean} beautify
 * @returns {string}
 */
export const cleanGeoJSON = (value, beautify = true) => {
  const cleanedGeoJSON = cleanGeoJSONLegacy(JSON.parse(value))
  const rawGeoJSON = JSON.stringify(cleanedGeoJSON)
  return beautify ? beautifyJSON(rawGeoJSON) : rawGeoJSON
}
