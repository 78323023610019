import feathers from '@feathersjs/client'
import {mappedRequests} from './mapRequests'
import {feathersDataProvider} from './lib/ra-feathers-client/dist/ra-feathers-client.es5'
import {feathersAuthProvider} from './lib/auth-provider'

let host = 'http://localhost:3030'
if (process.env.REACT_APP_API_HOST) {
  host = process.env.REACT_APP_API_HOST
}
const restClient = feathers.rest(host)
const client = feathers()
  .configure(restClient.fetch(window.fetch))
  .configure(
    feathers.authentication({
      jwtStrategy: 'jwt',
      storage: window.localStorage,
      storageKey: 'ra-feathers-token',
      path: 'api/v1/authentication',
    }),
  )

export const dataProvider = mappedRequests(feathersDataProvider(client))
export const authProvider = feathersAuthProvider(client)
