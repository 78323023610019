import React from 'react'
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin'
import {ListActions} from '../../components/ListActions'

const AppVersionsFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by version" source="version[$like]" alwaysOn />
  </Filter>
)

export const AppVersionsList = props => (
  <List
    {...props}
    title="Application Versions"
    actions={<ListActions />}
    bulkActionButtons={false}
    filters={<AppVersionsFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="version" />
      <TextField source="force_update" />
      <EditButton />
    </Datagrid>
  </List>
)
