import React, {Fragment, useState} from 'react'
import {connect} from 'react-redux'
import compose from 'recompose/compose'
import classnames from 'classnames'
import {Button, crudDelete, refreshView} from 'react-admin'
import {withStyles} from '@material-ui/core/styles'
import {fade} from '@material-ui/core/styles/colorManipulator'
import ActionDelete from '@material-ui/icons/Delete'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

const styles = theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
})

const DeleteWithConfirmationButton = props => {
  const {classes, title, content} = props
  const [showDialog, setShowDialog] = useState(false)

  const handleDeleteClick = () => {
    const {
      dispatchCrudDelete,
      dispatchRefreshView,
      resource,
      record,
      basePath,
    } = props

    dispatchCrudDelete(resource, record.id, record, basePath)
    // delay the refresh view action
    // to make sure the item has been deleted
    // before reloading the view
    setTimeout(() => {
      setShowDialog(false)
      dispatchRefreshView()
    }, 2000)
  }

  return (
    <Fragment>
      <Button
        label="Delete"
        onClick={() => setShowDialog(true)}
        className={classnames('ra-delete-button', classes.deleteButton)}
        key="button">
        <ActionDelete />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-label="Delete image">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <div>{content}</div>
        </DialogContent>
        <DialogActions>
          <Button
            label="Yes"
            onClick={() => handleDeleteClick()}
            className={classnames('ra-delete-button', classes.deleteButton)}>
            <ActionDelete />
          </Button>
          <Button label="ra.action.cancel" onClick={() => setShowDialog(false)}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default compose(
  connect(null, {
    dispatchRefreshView: refreshView,
    dispatchCrudDelete: crudDelete,
  }),
  withStyles(styles),
)(DeleteWithConfirmationButton)
