import React from 'react'
import Icon from '@material-ui/core/Icon'
import {SelectInput} from 'react-admin'
import {availableIcons, iconStyle} from './PageIcon'

const iconSelectRender = choice => {
  let icon = <Icon className={choice.id} />
  // specific icon from FA for the Page icon as it is not part if the icon set we get from WRC
  if (choice.id === 'page') {
    icon = <Icon className="icomoon-map-pin-silhouette" />
  }
  return (
    <span>
      <span style={iconStyle}>{icon}</span>
      <span>{choice.name}</span>
    </span>
  )
}

export const PageIconSelectInput = ({source}) => (
  <SelectInput
    translateChoice={false}
    source={source}
    choices={availableIcons}
    optionText={iconSelectRender}
  />
)
