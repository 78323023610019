import {Box} from '@material-ui/core'
import React from 'react'
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import LeafletMapMarkerShapeField from '../../components/LeafletMap/LeafletMapMarkerShapeField'
import ShapeReferenceInput from '../../components/ShapeReferenceInput'
import {maxLength, required} from '../../lib/common-validators'

const BylawAreasTitle = ({record}) => <Box>{record ? record.title : ''}</Box>

export const BylawAreasEdit = props => {
  return (
    <Edit
      {...props}
      undoable={false}
      redirect={false}
      title={<BylawAreasTitle />}>
      <SimpleForm>
        <HeaderWithPreviousButton title="Bylaw Area Edition" />
        <TextInput
          source="title"
          label="Title"
          validate={[required, maxLength(255)]}
          fullWidth={true}
        />
        <TextInput
          source="description"
          label="Description"
          validate={required}
          fullWidth={true}
          multiline={true}
        />
        <ReferenceInput
          label="Region"
          source="region_id"
          reference="region-zone"
          validate={required}>
          <SelectInput optionText="title" />
        </ReferenceInput>
        <ShapeReferenceInput />
        <LeafletMapMarkerShapeField />
      </SimpleForm>
    </Edit>
  )
}
