import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
} from 'draft-js'
import React from 'react'
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin'
import {ListActions} from '../../components/ListActions'

const LifeJacketsFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by title" source="title[$like]" alwaysOn />
  </Filter>
)

const DescriptionText = ({record, source}) => {
  const data = record[source]

  const convertedData = data
    ? JSON.parse(data)
    : convertToRaw(ContentState.createFromText(''))

  const contentState = convertFromRaw(convertedData)
  const editorState = EditorState.createWithContent(contentState)

  return (
    <Editor
      blockStyleFn={() => 'draftJsDisplayInTable'}
      editorState={editorState}
      readOnly={true}
    />
  )
}

export const LifeJacketsList = props => (
  <List
    {...props}
    title="Life Jackets Channels List"
    actions={<ListActions />}
    bulkActionButtons={false}
    filters={<LifeJacketsFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <DescriptionText source="description" />
      <EditButton />
    </Datagrid>
  </List>
)
