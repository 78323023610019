import React, {Fragment, useState} from 'react'
import {
  Edit,
  required,
  Button,
  SaveButton,
  SimpleForm,
  TextInput,
} from 'react-admin'
import IconContentAdd from '@material-ui/icons/Add'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

export const GalleryImageEdit = ({image, resource, basePath, location}) => {
  const [showDialog, setShowDialog] = useState(false)

  return (
    <Fragment>
      <Button onClick={() => setShowDialog(true)} label="Edit">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-label="Edit image">
        <DialogTitle>Edit image</DialogTitle>
        <DialogContent>
          <Edit
            undoable={false}
            basePath={basePath}
            location={location}
            resource={resource}
            id={image.id}>
            <SimpleForm
              resource={resource}
              toolbar={
                <FormToolBar onCancelClick={() => setShowDialog(false)} />
              }
              initialValues={{title: image.title}}>
              <TextInput source="title" validate={required()} autoFocus />
            </SimpleForm>
          </Edit>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

const FormToolBar = ({onCancelClick, handleSubmitWithRedirect}) => {
  return (
    <DialogActions>
      <SaveButton
        redirect={false}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        submitOnEnter={false}
      />
      <Button label="ra.action.cancel" onClick={onCancelClick}>
        <IconCancel />
      </Button>
    </DialogActions>
  )
}
