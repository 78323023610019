import React from 'react'
import {BooleanInput, Create, SimpleForm, TextInput} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import {maxLength, required} from '../../lib/common-validators'

export const SettingsCreate = props => (
  <Create {...props} title="Create setting">
    <SimpleForm>
      <HeaderWithPreviousButton title="Setting creation" />
      <TextInput
        source="name"
        label="name"
        validate={[required, maxLength(255)]}
      />
      <TextInput source="value" label="value" />
      <TextInput source="description" multiline />
      <BooleanInput source="enable" />
    </SimpleForm>
  </Create>
)
