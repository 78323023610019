import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
} from 'react-admin'
import {PageIconSelectInput} from '../../components/PageIconSelectInput'

export const PageCreate = props => (
  <Create undoabl={false} {...props} title="Create CMS Page">
    <SimpleForm>
      <PageIconSelectInput source="icon" />
      <TextInput source="title" label="Title" />
      <ReferenceInput
        label="Category"
        source="category_id"
        reference="category">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <NumberInput source="order" step={1} />
    </SimpleForm>
  </Create>
)
