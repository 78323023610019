import React from 'react'
import {Login, LoginForm} from 'react-admin'
import {withStyles} from '@material-ui/core/styles'

const styles = {
  main: {
    backgroundColor: '#00B2A9',
    backgroundSize: '250px;',
    backgroundPosition: 'top 0px center',
  },
  card: {
    marginTop: '220px',
  },
  avatar: {
    margin: 0,
  },
  icon: {display: 'none'},
}

const CustomLoginForm = withStyles({
  button: {
    background: '#00B2A9',
    '&:hover': {
      background: '#327A76',
    },
  },
})(LoginForm)

const CustomLoginPage = props => (
  <Login
    backgroundImage="images/logo.png"
    loginform={<CustomLoginForm />}
    {...props}
  />
)

export default withStyles(styles)(CustomLoginPage)
