import React from 'react'
import {
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin'
import {ListActions} from '../../components/ListActions'

const VhfFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by title" source="title[$like]" alwaysOn />
    <TextInput
      label="Search by description"
      source="description[$like]"
      alwaysOn
    />
  </Filter>
)

export const VhfList = props => (
  <List
    {...props}
    title="VHF Channels List"
    actions={<ListActions />}
    bulkActionButtons={false}
    filters={<VhfFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="description" />
      <DateField source="updated_at" showTime />
      <DateField source="created_at" showTime />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)
