import {Box} from '@material-ui/core'
import React from 'react'
import {
  Edit,
  FormTab,
  ReferenceManyField,
  TabbedForm,
  TextInput,
} from 'react-admin'
import {GalleryImage} from '../../components/GalleryImage'
import {GalleryImageUpload} from '../../components/GalleryImageUpload'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import LeafletMapMarkerField from '../../components/LeafletMap/LeafletMapMarkerField'
import {maxLength, required} from '../../lib/common-validators'

const HazardTitle = ({record}) => <Box>{record ? record.title : ''}</Box>

export const HazardEdit = props => {
  const {location, basePath} = props

  return (
    <Edit undoable={false} {...props} title={<HazardTitle />}>
      <TabbedForm>
        <FormTab label="Hazard">
          <HeaderWithPreviousButton title="Hazard Edition" />
          <TextInput
            source="title"
            validate={[required, maxLength(255)]}
            fullWidth={true}
          />
          <LeafletMapMarkerField />
        </FormTab>
        <FormTab label="Gallery">
          <ReferenceManyField
            reference="gallery-image"
            target="poi_id"
            label="">
            <GalleryImage location={location} basePath={basePath} />
          </ReferenceManyField>
          <GalleryImageUpload />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
