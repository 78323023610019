import React from 'react'
import {BooleanInput, Create, SimpleForm, TextInput} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import {maxLength, required} from '../../lib/common-validators'

export const AppVersionCreate = props => (
  <Create {...props} title="Create App Version">
    <SimpleForm>
      <HeaderWithPreviousButton title="App Version Creation" />
      <TextInput
        source="version"
        label="Version"
        validate={[required, maxLength(255)]}
      />
      <BooleanInput source="force_update" />
    </SimpleForm>
  </Create>
)
