import React from 'react'
import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin'
import {ListActions} from '../../components/ListActions'

const BylawAreasFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by title" source="title[$like]" alwaysOn />
    <TextInput
      label="Search by description"
      source="description[$like]"
      alwaysOn
    />
    <ReferenceInput
      label="Select a region"
      source="region_id"
      reference="region-zone"
      alwaysOn
      allowEmpty>
      <SelectInput optionText="title" />
    </ReferenceInput>
  </Filter>
)

export const BylawAreasList = props => (
  <List
    {...props}
    title="Bylaw Areas List"
    actions={<ListActions />}
    bulkActionButtons={false}
    filters={<BylawAreasFilter />}>
    <Datagrid>
      <TextField source="title" />
      <ReferenceField source="region_id" reference="region-zone">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField source="shape_id" reference="shape" label="Shape id">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField
        label="KML filename (legacy)"
        source="shape_id"
        reference="shape"
        link={false}>
        <TextField source="file_identifier" />
      </ReferenceField>
      <DateField source="updated_at" showTime />
      <EditButton />
    </Datagrid>
  </List>
)
