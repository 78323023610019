import React from 'react'
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin'
import {ListActions} from '../../components/ListActions'

const Filters = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="name[$like]" alwaysOn />
    <TextInput label="Search by value" source="value[$like]" alwaysOn />
    <TextInput
      label="Search by description"
      source="description[$like]"
      alwaysOn
    />
  </Filter>
)

export const SettingsList = props => (
  <List
    {...props}
    title="Settings"
    actions={<ListActions />}
    bulkActionButtons={false}
    filters={<Filters />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="value" />
      <TextField source="enable" />
      <TextField source="description" />
      <EditButton />
    </Datagrid>
  </List>
)
