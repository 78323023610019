import React, {useCallback} from 'react'
import {Field} from 'react-final-form'
import {Editor} from 'react-draft-wysiwyg'
import {convertToRaw, ContentState} from 'draft-js'

const toolbar = {
  options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'history'],
  blockType: {
    options: ['Normal', 'H1', 'H2', 'Blockquote'],
  },
  link: {
    showOpenOptionOnHover: true,
    defaultTargetOption: '_blank',
    options: ['link', 'unlink'],
  },
}

const EditorComponent = ({input, record, fieldKey}) => {
  const data = record[fieldKey]

  const convertedData =
    data && data !== '{}'
      ? JSON.parse(data)
      : convertToRaw(ContentState.createFromText(''))

  const handleOnChange = useCallback(
    newData => {
      input.onChange(JSON.stringify(newData))
    },
    [input],
  )

  return (
    <div
      style={{
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 0,
        borderColor: '#f1f1f1',
        borderStyle: 'solid',
      }}>
      <input type="hidden" value={input.value} name={input.name} />
      <Editor
        toolbar={toolbar}
        initialContentState={convertedData}
        onContentStateChange={handleOnChange}
      />
    </div>
  )
}

export const RichTextInput = ({source, record}) => {
  return (
    <Field name={source}>
      {({input}) => (
        <EditorComponent input={input} record={record} fieldKey={source} />
      )}
    </Field>
  )
}
