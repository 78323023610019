import {Box, Typography} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'
import AdjustSharp from '@material-ui/icons/AdjustSharp'
import 'ace-builds'
import 'ace-builds/webpack-resolver'
import {debounce} from 'lodash'
import React, {useContext, useEffect, useState} from 'react'
import AceEditor from 'react-ace'
import {Button} from 'react-admin'
import {useTypographyStyles} from '../../hooks/useTypographyStyles'
import {cleanGeoJSON, minifyJSON} from '../../utils/helpers'
import {Flex} from '../Layout'
import {ShapeContext} from '../ShapeField'

export const AceEditorJson = ({
  input,
  record,
  source,
  width = '100%',
  isFieldInvalid = false,
}) => {
  const {caption} = useTypographyStyles()

  const {isSimplifyEnabled} = useContext(ShapeContext)

  const [value, setValue] = useState(
    record[source] ? cleanGeoJSON(record[source]) : null,
  )

  useEffect(() => {
    if (isSimplifyEnabled) {
      setValue(cleanGeoJSON(input.value))
    }
  }, [input.value, isSimplifyEnabled])

  const {
    palette: {
      error: {main: errorColor},
      grey,
    },
  } = useTheme()

  const onBeautify = debounce(nextValue => {
    const editedValue = cleanGeoJSON(nextValue)
    setValue(editedValue)
    input.onChange(minifyJSON(editedValue))
  }, 20)

  const onChangeValue = debounce(value => {
    setValue(value)
    input.onChange(minifyJSON(value))
  })

  return (
    <Box
      position="relative"
      width={width}
      height="100%"
      borderColor={!isFieldInvalid ? grey[300] : errorColor}
      border={1}>
      <Box overflow="hidden" height="100%">
        <AceEditor
          mode="json"
          theme="tomorrow"
          onChange={onChangeValue}
          onPaste={onBeautify}
          name="coords"
          wrapEnabled={true}
          value={value}
          width="100%"
          height="100%"
        />
      </Box>
      <Box
        position="absolute"
        right={25}
        top={10}
        zIndex={1}
        background="#fff"
        borderRadius={4}
        borderColor={grey[300]}
        border={1}>
        <Button label="Beautify" onClick={() => onBeautify(value)}>
          <AdjustSharp />
        </Button>
      </Box>

      {isSimplifyEnabled && (
        <Flex
          position="absolute"
          width="100%"
          height="100%"
          left={0}
          top={0}
          bgcolor="rgb(255 255 255 / 75%)"
          zIndex={9999}
          textAlign="center">
          <Typography className={caption} color="primary">
            Edition disabled while using simplify mode
          </Typography>
        </Flex>
      )}
    </Box>
  )
}

export default AceEditorJson
