import React from 'react'
import {
  Edit,
  SimpleShowLayout,
  Show,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ReferenceManyField,
  Tab,
  TabbedShowLayout,
  NumberInput,
} from 'react-admin'
import {maxLength, required} from '../../lib/common-validators'
import {PageIconSelectInput} from '../../components/PageIconSelectInput'
import {PageBlocksInput} from '../../components/PageBlocksInput'
import {PageBlockAddButtons} from '../../components/PageBlockAddButtons'

export const PageEdit = props => {
  const {basePath, resource, id, location} = props

  return (
    <Show {...props} actions={null}>
      <TabbedShowLayout>
        <Tab label="Page Meta">
          <Edit
            undoable={false}
            basePath={basePath}
            resource={resource}
            id={id}
            title={<PageEditTitle />}>
            <SimpleForm>
              <PageIconSelectInput source="icon" />
              <TextInput
                source="title"
                label="Title"
                validate={[required, maxLength(255)]}
              />
              <ReferenceInput
                label="Category"
                source="category_id"
                reference="category"
                validate={required}>
                <SelectInput optionText="title" />
              </ReferenceInput>
              <NumberInput source="order" step={1} />
            </SimpleForm>
          </Edit>
        </Tab>
        <Tab label="Content">
          <SimpleShowLayout>
            <ReferenceManyField
              label=""
              reference="page-block"
              target="page_id">
              <PageBlocksInput location={location} />
            </ReferenceManyField>
          </SimpleShowLayout>
          <PageBlockAddButtons
            id={id}
            location={location}
            basePath={basePath}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

const PageEditTitle = ({record}) => {
  return <span>{record ? record.title : ''}</span>
}
