import React from 'react'
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import {DateTimeInput} from 'react-admin-date-inputs'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import {RichTextInput} from '../../components/RichTextInput'
import {maxLength, required} from '../../lib/common-validators'

export const NoticeCreate = props => (
  <Create {...props} title="Notice Creation">
    <SimpleForm>
      <HeaderWithPreviousButton title="Notice Creation" />
      <TextInput
        source="title"
        label="Title"
        validate={[required, maxLength(255)]}
      />
      <DateTimeInput
        label="Published At"
        source="published_at"
        options={{clearable: true}}
      />
      <DateTimeInput
        label="Expired At"
        source="expired_at"
        options={{clearable: true}}
      />
      <TextInput source="event_at" label="Event At" />
      <TextInput source="published_by" label="Published By" />

      <RichTextInput source="richtext_description" />

      <ReferenceInput
        label="Region"
        source="region_id"
        reference="region-zone"
        validate={required}>
        <SelectInput optionText="title" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)
