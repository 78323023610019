import React, {Fragment} from 'react'
import {TextField} from 'react-admin'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import {GalleryImageEdit} from './GalleryImageEdit'
import DeleteWithConfirmationButton from './DeleteWithConfirmationButton'
import {IMAGE_URL} from '../config'

const cardStyle = {
  width: 300,
  height: 300,
  margin: '0.5em',
  display: 'inline-block',
  verticalAlign: 'top',
}

export const GalleryImage = ({ids, data, resource}) => (
  <Fragment>
    {ids &&
      ids.map(id => (
        <Card key={id} style={cardStyle}>
          <CardHeader title={<TextField record={data[id]} source="title" />} />
          <CardContent>
            <img
              src={`${IMAGE_URL}/${data[id].file_path}`}
              alt={data[id].title}
              style={{maxWidth: '100%', maxHeight: '150px'}}
            />
          </CardContent>
          <CardActions style={{textAlign: 'right'}}>
            <GalleryImageEdit resource={resource} image={data[id]} />
            <DeleteWithConfirmationButton
              resource={resource}
              record={data[id]}
              title="Delete image"
              content="Are you sure?"
            />
          </CardActions>
        </Card>
      ))}
  </Fragment>
)
