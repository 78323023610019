import React from 'react'
import {Edit, SimpleForm, TextInput} from 'react-admin'
import {FakePasswordField} from '../../components/FakePasswordField'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'

export const UserEdit = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <HeaderWithPreviousButton title="User Edition" />
      <FakePasswordField />
      <TextInput disabled source="email" />
      <TextInput source="password" type="password" />
    </SimpleForm>
  </Edit>
)
