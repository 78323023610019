import React from 'react'
import {
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin'
import {ListActions} from '../../components/ListActions'

const TidesFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by title" source="title[$like]" alwaysOn />
  </Filter>
)

export const TidesList = props => (
  <List
    {...props}
    title="Tide Tables List"
    actions={<ListActions />}
    bulkActionButtons={false}
    filters={<TidesFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <DateField source="updated_at" showTime />
      <DateField source="created_at" showTime />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)
