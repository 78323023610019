import React from 'react'
import {Create, SimpleForm, TextInput} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import ShapeField from '../../components/ShapeField'
import {maxLength, required} from '../../lib/common-validators'

export const ShapeCreate = props => {
  return (
    <Create {...props} title="Shape Creation">
      <SimpleForm>
        <HeaderWithPreviousButton title="Shape Creation" />
        <TextInput
          source="name"
          label="Name"
          validate={[required, maxLength(255)]}
          fullWidth={true}
        />
        <ShapeField source="coords" />
      </SimpleForm>
    </Create>
  )
}
