import {Box} from '@material-ui/core'
import React from 'react'
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import {Field} from 'react-final-form'
import {renderEditor} from '../../components/EditorField'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import {maxLength, required} from '../../lib/common-validators'

const BylawTitle = ({record}) => <Box m={0}>{record ? record.title : ''}</Box>

export const BylawEdit = props => (
  <Edit undoable={false} {...props} title={<BylawTitle />}>
    <SimpleForm>
      <HeaderWithPreviousButton title="Bylaw Edition" />
      <TextInput
        source="title"
        label="Title"
        fullWidth={true}
        validate={[required, maxLength(255)]}
      />
      <Field
        name="richtext_description"
        component={renderEditor}
        label="Description"
        validate={required}
      />
      <ReferenceInput
        label="Region"
        source="region_id"
        reference="region-zone"
        validate={required}>
        <SelectInput optionText="title" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)
