import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import {FormHelperText} from '@material-ui/core'
import {Editor} from 'react-draft-wysiwyg'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const toolbar = {
  options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'history'],
  blockType: {
    options: ['Normal', 'H1', 'H2', 'Blockquote'],
  },
  link: {
    showOpenOptionOnHover: true,
    defaultTargetOption: '_blank',
    options: ['link', 'unlink'],
  },
}

export const renderEditor = ({input, meta: {touched, error, warning}}) => {
  return (
    <div style={{border: '1px solid #eee', padding: '10px'}}>
      <EditorField input={input} />
      {touched &&
        ((error && <FormHelperText error={true}>{error}</FormHelperText>) ||
          (warning && <FormHelperText error={true}>{warning}</FormHelperText>))}
    </div>
  )
}

const EditorField = ({input}) => {
  let data = null
  if (input.value && typeof input.value === 'string') {
    try {
      data = JSON.parse(input.value)
    } catch (e) {
      data = null
    }
  } else if (typeof input.value === 'object') {
    data = input.value
  }

  const setEditorFocus = ref => {
    if (ref) {
      ref.focus()
    }
  }

  const getBlockClass = contentBlock => {
    const type = contentBlock.getType()
    switch (type) {
      case 'blockquote':
        return 'pageBlock-quote'
      case 'header-one':
        return 'pageBlock-heading'
      case 'header-two':
        return 'pageBlock-heading2'
      case 'unstyled':
        return 'pageBlock-paragraph'
      default:
        return undefined
    }
  }

  const onContentStateChange = contentState => {
    input.onChange(contentState)
  }

  return (
    <FormControl>
      <div>
        <Editor
          editorRef={setEditorFocus}
          blockStyleFn={getBlockClass}
          stripPastedStyles
          toolbar={toolbar}
          defaultContentState={data}
          onContentStateChange={onContentStateChange}
        />
      </div>
    </FormControl>
  )
}
