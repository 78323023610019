import {fade} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'

const styles = theme => ({
  heading: {
    color: fade(theme.palette.primary.main, 1),
  },
  errorLink: {
    color: fade(theme.palette.error.main, 1),
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  caption: {
    width: '100%',
    fontStyle: 'italic',
  },
})

export const useTypographyStyles = makeStyles(styles)
