import React from 'react'
import {Create, FileField, FileInput, SimpleForm, TextInput} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import LeafletMapMarkerField from '../../components/LeafletMap/LeafletMapMarkerField'
import {maxLength, required} from '../../lib/common-validators'

export const TideCreate = props => (
  <Create {...props} title="Create Tide Table">
    <SimpleForm>
      <HeaderWithPreviousButton title="Tide Table Creation" />
      <TextInput
        source="title"
        validate={[required, maxLength(255)]}
        fullWidth={true}
      />
      <LeafletMapMarkerField />
      <FileInput
        source="csv_file"
        label="CSV"
        accept="text/csv"
        validate={required}>
        <FileField source="src" title="filename" />
      </FileInput>
    </SimpleForm>
  </Create>
)
