import React, {useState} from 'react'
import {SimpleForm, TextInput, required, regex, Edit} from 'react-admin'

export const PageBlockVideoInput = ({record, location, basePath}) => {
  let data = record.data && record.data !== '{}' ? JSON.parse(record.data) : ''
  if (record.data) {
    data = JSON.parse(record.data)
    if (data.videoId) {
      record.videoId = data.videoId
    } else {
      record.videoId = ''
    }
  }
  const validateVideoId = regex(
    /^[a-zA-Z0-9_-]{11}$/,
    'Please provide a valid youtube video ID',
  )
  const [previewUrl, setPreviewUrl] = useState(data.previewUrl)

  return (
    <Edit
      undoable={false}
      resource="page-block"
      record={record}
      location={location}
      basePath={basePath}
      id={record.id}
      title={<></>}>
      <SimpleForm>
        <TextInput
          id={`video-input-${record.id}`}
          multiline
          source="videoId"
          label="Youtube Video ID"
          validate={[required(), validateVideoId]}
          onChange={value => setPreviewUrl(value)}
        />
        {previewUrl && <PreviewImage url={previewUrl} />}
      </SimpleForm>
    </Edit>
  )
}

const PreviewImage = ({url}) => <img src={url} alt="video thumbnail" />
