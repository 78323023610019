import React, {Component} from 'react'
import {Admin, Resource} from 'react-admin'
import Icon from '@material-ui/core/Icon'

import './App.css'
import {dataProvider, authProvider} from './providers'
import {UsersList} from './resources/users/UsersList'
import {UserCreate} from './resources/users/UserCreate'
import {UserEdit} from './resources/users/UserEdit'
import {RegionZonesList} from './resources/region-zones/RegionZonesList'
import {RegionZoneCreate} from './resources/region-zones/RegionZoneCreate'
import {RegionZoneEdit} from './resources/region-zones/RegionZoneEdit'
import {TidesList} from './resources/tides/TidesList'
import {TideCreate} from './resources/tides/TideCreate'
import {TideEdit} from './resources/tides/TideEdit'
import {VhfList} from './resources/vhf/VhfList'
import {ReservesList} from './resources/reserves/ReservesList'
import {ReservesEdit} from './resources/reserves/ReservesEdit'
import {VhfCreate} from './resources/vhf/VhfCreate'
import {VhfEdit} from './resources/vhf/VhfEdit'
import {BylawsList} from './resources/bylaws/BylawsList'
import {BylawCreate} from './resources/bylaws/BylawCreate'
import {BylawEdit} from './resources/bylaws/BylawEdit'
import {NoticesList} from './resources/notices/NoticesList'
import {NoticeCreate} from './resources/notices/NoticeCreate'
import {NoticeEdit} from './resources/notices/NoticeEdit'
import {PagesList} from './resources/pages/PagesList'
import {PageCreate} from './resources/pages/PageCreate'
import {PageEdit} from './resources/pages/PageEdit'
import {BylawAreasList} from './resources/bylaw-areas/BylawAreasList'
import {BylawAreasCreate} from './resources/bylaw-areas/BylawAreasCreate'
import {ReservesCreate} from './resources/reserves/ReservesCreate'
import {BoatRampsList} from './resources/boat-ramps/BoatRampsList'
import {BoatRampCreate} from './resources/boat-ramps/BoatRampCreate'
import {BoatRampEdit} from './resources/boat-ramps/BoatRampEdit'
import {HazardsList} from './resources/hazards/HazardsList'
import {HazardCreate} from './resources/hazards/HazardCreate'
import {HazardEdit} from './resources/hazards/HazardEdit'
import {MataitaiList} from './resources/mataitai/MataitaiList'
import {MataitaiCreate} from './resources/mataitai/MataitaiCreate'
import {MataitaiEdit} from './resources/mataitai/MataitaiEdit'
import {TaiapureList} from './resources/taiapure/TaiapureList'
import {TaiapureCreate} from './resources/taiapure/TaiapureCreate'
import {TaiapureEdit} from './resources/taiapure/TaiapureEdit'
import LoginPage from './components/LoginPage'
import {theme} from './themes/marine-mate'
import UserIcon from '@material-ui/icons/Person'
import NoticeRegionIcon from '@material-ui/icons/Warning'
import NoticeIcon from '@material-ui/icons/AddAlert'
import VHFIcon from '@material-ui/icons/Radio'
import BylawIcon from '@material-ui/icons/Book'
import BoatRampIcon from '@material-ui/icons/DirectionsBoat'
import HazardIcon from '@material-ui/icons/ErrorOutline'
import AppVersionIcon from '@material-ui/icons/PhoneIphone'
import SettingsIcon from '@material-ui/icons/Settings'
import ShapesIcon from '@material-ui/icons/FormatShapes'
import CMSIcon from '@material-ui/icons/Edit'
import {MammalAreasList} from './resources/mammal-areas/MammalAreasList'
import {MammalAreasCreate} from './resources/mammal-areas/MammalAreasCreate'
import {MammalAreasEdit} from './resources/mammal-areas/MammalAreasEdit'
import {AppVersionsList} from './resources/app-versions/AppVersionList'
import {AppVersionCreate} from './resources/app-versions/AppVersionCreate'
import {AppVersionEdit} from './resources/app-versions/AppVersionEdit'
import {BylawAreasEdit} from './resources/bylaw-areas/BylawAreasEdit'
import {LifeJacketsList} from './resources/life-jackets/LifeJacketsList'
import {LifeJacketsCreate} from './resources/life-jackets/LifeJacketsCreate'
import {LifeJacketsEdit} from './resources/life-jackets/LifeJacketsEdit'
import {SettingsList} from './resources/settings/SettingsList'
import {SettingsCreate} from './resources/settings/SettingsCreate'
import {SettingsEdit} from './resources/settings/SettingsEdit'
import {ShapeList} from './resources/shapes/ShapeList'
import {ShapeEdit} from './resources/shapes/ShapeEdit'
import {ShapeCreate} from './resources/shapes/ShapeCreate'

class App extends Component {
  render() {
    return (
      <Admin
        theme={theme}
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={LoginPage}>
        {permissions => [
          <Resource
            name="notice"
            icon={NoticeIcon}
            list={NoticesList}
            create={NoticeCreate}
            edit={NoticeEdit}
          />,
          <Resource name="category" />,
          <Resource name="marker" />,
          <Resource name="tide" />,
          <Resource name="page-block" />,
          <Resource name="gallery-image" />,
          <Resource
            name="region-zone"
            icon={NoticeRegionIcon}
            options={{label: 'Notice Regions'}}
            list={permissions === 'admin' ? RegionZonesList : null}
            create={permissions === 'admin' ? RegionZoneCreate : null}
            edit={permissions === 'admin' ? RegionZoneEdit : null}
          />,
          permissions === 'admin' ? (
            <Resource
              name="tide-table"
              icon={() => <Icon className="icomoon-tide" />}
              options={{label: 'Tide Tables'}}
              list={TidesList}
              create={TideCreate}
              edit={TideEdit}
            />
          ) : null,
          permissions === 'admin' ? (
            <Resource
              name="vhf"
              icon={VHFIcon}
              options={{label: 'VHF Channels'}}
              list={VhfList}
              create={VhfCreate}
              edit={VhfEdit}
            />
          ) : null,
          permissions === 'admin' ? (
            <Resource
              name="marine-reserve"
              icon={() => <Icon className="icomoon-DoC" />}
              options={{label: 'Marine Reserves'}}
              list={ReservesList}
              create={ReservesCreate}
              edit={ReservesEdit}
            />
          ) : null,
          permissions === 'admin' ? (
            <Resource
              name="mataitai"
              icon={() => <Icon className="icomoon-mpi" />}
              options={{label: 'Mātaitai'}}
              list={MataitaiList}
              create={MataitaiCreate}
              edit={MataitaiEdit}
            />
          ) : null,
          permissions === 'admin' ? (
            <Resource
              name="taiapure"
              icon={() => <Icon className="icomoon-mpi" />}
              options={{label: 'Taiāpure'}}
              list={TaiapureList}
              create={TaiapureCreate}
              edit={TaiapureEdit}
            />
          ) : null,
          permissions === 'admin' ? (
            <Resource
              name="bylaw"
              icon={BylawIcon}
              list={BylawsList}
              create={BylawCreate}
              edit={BylawEdit}
            />
          ) : null,
          permissions === 'admin' ? (
            <Resource
              name="bylaw-area"
              icon={BylawIcon}
              options={{label: 'Bylaw Areas'}}
              list={BylawAreasList}
              create={BylawAreasCreate}
              edit={BylawAreasEdit}
            />
          ) : null,
          permissions === 'admin' ? (
            <Resource
              name="page"
              icon={CMSIcon}
              list={PagesList}
              create={PageCreate}
              edit={PageEdit}
              options={{label: 'CMS'}}
            />
          ) : null,
          permissions === 'admin' ? (
            <Resource
              name="boat-ramp"
              icon={BoatRampIcon}
              options={{label: 'Boat Ramps'}}
              list={BoatRampsList}
              create={BoatRampCreate}
              edit={BoatRampEdit}
            />
          ) : null,
          permissions === 'admin' ? (
            <Resource
              name="hazard"
              icon={HazardIcon}
              list={HazardsList}
              create={HazardCreate}
              edit={HazardEdit}
            />
          ) : null,
          permissions === 'admin' ? (
            <Resource
              name="mammal-area"
              icon={() => <Icon className="icomoon-mammal" />}
              options={{label: 'Mammal Areas'}}
              list={MammalAreasList}
              create={MammalAreasCreate}
              edit={MammalAreasEdit}
            />
          ) : null,
          permissions === 'admin' ? (
            <Resource
              name="life-jackets"
              icon={() => <Icon className="icomoon-life-jacket" />}
              options={{label: 'Life Jackets'}}
              list={LifeJacketsList}
              create={LifeJacketsCreate}
              edit={LifeJacketsEdit}
            />
          ) : null,
          permissions === 'admin' ? (
            <Resource
              name="shape"
              icon={ShapesIcon}
              options={{label: 'Shapes'}}
              list={ShapeList}
              create={ShapeCreate}
              edit={ShapeEdit}
            />
          ) : null,
          permissions === 'admin' ? (
            <Resource
              name="app-version"
              icon={AppVersionIcon}
              options={{label: 'Application Versions'}}
              list={AppVersionsList}
              create={AppVersionCreate}
              edit={AppVersionEdit}
            />
          ) : null,
          permissions === 'admin' ? (
            <Resource
              name="users"
              icon={UserIcon}
              list={UsersList}
              create={UserCreate}
              edit={UserEdit}
            />
          ) : null,
          permissions === 'admin' ? (
            <Resource
              name="settings"
              icon={SettingsIcon}
              options={{label: 'Settings'}}
              list={SettingsList}
              create={SettingsCreate}
              edit={SettingsEdit}
            />
          ) : null,
        ]}
      </Admin>
    )
  }
}

export default App
