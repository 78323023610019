import {Box} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import React from 'react'
import {Button, ReferenceInput, SelectInput} from 'react-admin'
import {Field} from 'react-final-form'
import {useHistory} from 'react-router-dom'
import {required} from '../lib/common-validators'
import ShapeQuickCreation from '../resources/shapes/ShapeQuickCreation'
import {Flex} from './Layout'

const ShapeReferenceInput = ({
  isSelectDisabled = true,
  isCreationAllowed = false,
}) => {
  const history = useHistory()

  const goToShape = shapeId => {
    history.push(`/shape/${shapeId}`)
  }

  return (
    <Flex justifyContent="start">
      <ReferenceInput
        label="Shape"
        source="shape_id"
        reference="shape"
        perPage={10_000}
        validate={required}>
        <SelectInput optionText="name" disabled={isSelectDisabled} />
      </ReferenceInput>

      <Box ml={2}>
        <Field name="shape_id" source="shape_id" validate={required}>
          {({input}) =>
            input.value && (
              <Button onClick={() => goToShape(input.value)} label="Edit">
                <EditIcon />
              </Button>
            )
          }
        </Field>
      </Box>

      {isCreationAllowed && <ShapeQuickCreation />}
    </Flex>
  )
}

export default ShapeReferenceInput
