import React, {useState} from 'react'
import {ImageInput, ImageField} from 'react-admin'
import {IMAGE_URL} from '../config'

export const ImageInputWithPreview = props => {
  const {record, onChange, source} = props
  const [hasChanged, setHasChanged] = useState(false)
  const imageUrl = `${IMAGE_URL}/${record.file_path}`
  const imagePreview = record.file_path && (
    <img src={imageUrl} style={{height: 160}} alt="preview" />
  )
  const changeCallback = data => {
    setHasChanged(true)
    onChange && onChange(data)
  }

  return (
    <>
      <ImageInput
        source={source}
        label="Image"
        accept="image/*"
        options={{
          onChange: changeCallback,
        }}
        {...props}>
        <ImageField source="file_path" title="filename" />
      </ImageInput>
      {!hasChanged && imagePreview}
    </>
  )
}
