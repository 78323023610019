import {Box} from '@material-ui/core'
import React from 'react'
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import {DateTimeInput} from 'react-admin-date-inputs'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import {RichTextInput} from '../../components/RichTextInput'
import {maxLength, required} from '../../lib/common-validators'

const NoticeTitle = ({record}) => <Box>{record ? record.title : ''}</Box>

export const NoticeEdit = props => (
  <Edit undoable={false} {...props} title={<NoticeTitle />}>
    <SimpleForm>
      <HeaderWithPreviousButton title="Notice Edition" />

      <TextInput
        source="title"
        label="Title"
        validate={[required, maxLength(255)]}
      />
      <DateTimeInput
        label="Published At"
        source="published_at"
        options={{clearable: true}}
      />
      <DateTimeInput
        label="Expired At"
        source="expired_at"
        options={{clearable: true}}
      />
      <TextInput source="event_at" label="Event At" />
      <TextInput source="published_by" label="Published By" />

      <RichTextInput source="richtext_description" />

      <ReferenceInput
        label="Region"
        source="region_id"
        reference="region-zone"
        validate={required}>
        <SelectInput optionText="title" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)
