import {fade} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'

const styles = theme => ({
  saveButton: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    color: 'white',
    backgroundColor: fade(theme.palette.primary.main, 1),
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.6),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  leafletButton: {
    backgroundColor: '#fff',
    color: '#161b1d',
    pointerEvents: 'auto',
    border: '2px solid rgba(0,0,0,0.2)',
    '&:hover': {
      backgroundColor: '#f3f3f3',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
})

export const useButtonStyles = makeStyles(styles)
