import React from 'react'
import {
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  Filter,
  List,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin'
import {ListActions} from '../../components/ListActions'

const BylawsFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by title" source="title[$like]" alwaysOn />
    <ReferenceInput
      label="Select a region"
      source="region_id"
      reference="region-zone"
      alwaysOn
      allowEmpty>
      <SelectInput optionText="title" />
    </ReferenceInput>
  </Filter>
)

export const BylawsList = props => (
  <List
    {...props}
    actions={<ListActions />}
    bulkActionButtons={false}
    filters={<BylawsFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <DateField source="updated_at" showTime />
      <DateField source="created_at" showTime />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)
