import {Box, InputLabel} from '@material-ui/core'
import React, {useMemo} from 'react'
import {NumberInput} from 'react-admin'
import {useFormState} from 'react-final-form'
import {numberRequired} from '../../lib/common-validators'
import {Flex} from '../Layout'
import LeafletMap from './LeafletMap'

const LeafletMapMarkerField = () => {
  return (
    <Box marginTop={2}>
      <InputLabel>Marker position</InputLabel>

      <Flex justifyContent="start" alignItems="start">
        <Box mr={3} width={300}>
          <NumberInput
            source="lat"
            label="Latitude"
            validate={numberRequired}
            fullWidth={true}
          />
        </Box>
        <Box width={300}>
          <NumberInput
            source="lng"
            label="Longitude"
            validate={numberRequired}
            fullWidth={true}
          />
        </Box>
      </Flex>

      <LeafletMapMarkerContainer />
    </Box>
  )
}

export default LeafletMapMarkerField

const LeafletMapMarkerContainer = ({height = 500}) => {
  const {values} = useFormState()

  const marker = useMemo(() => {
    if (values.lat && values.lng) {
      return [values.lat, values.lng]
    }
  }, [values.lat, values.lng])

  return (
    <Box height={height} mt={2}>
      <LeafletMap zoom={marker ? 8 : 5} marker={marker} center={marker} />
    </Box>
  )
}
