import React from 'react'
import Icon from '@material-ui/core/Icon'

export const availableIcons = [
  {id: 'page', name: 'page'},
  {id: 'icomoon-map-1', name: 'map 1'},
  {id: 'icomoon-map', name: 'map'},
  {id: 'icomoon-error-triangle', name: 'error triangle'},
  {
    id: 'icomoon-exclamation-mark-in-a-circle',
    name: 'exclamation mark in a circle',
  },
  {id: 'icomoon-life-vest', name: 'life vest'},
  {id: 'icomoon-life-vest-1', name: 'life vest 1'},
  {id: 'icomoon-life-jacket', name: 'life jacket'},
  {id: 'icomoon-location', name: 'location'},
  {id: 'icomoon-gps', name: 'gps'},
  {id: 'icomoon-globe', name: 'globe'},
  {id: 'icomoon-global', name: 'global'},
  {id: 'icomoon-map-pin-silhouette', name: 'map pin silhouette'},
  {id: 'icomoon-radio', name: 'radio'},
  {id: 'icomoon-placeholder', name: 'placeholder'},
  {id: 'icomoon-tide', name: 'tide'},
  {id: 'icomoon-mammal', name: 'mammal'},
]

export const iconStyle = {
  width: '40px',
  display: 'inline-block',
}

export const PageIcon = ({record, source}) => {
  const icon = record[source]
  let component = <Icon className={icon} />
  if (icon === 'page') {
    component = <Icon className="icomoon-map-pin-silhouette" />
  }

  return component
}
