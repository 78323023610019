import React from 'react'
import {Create, SimpleForm, TextInput} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import LeafletMapMarkerShapeField from '../../components/LeafletMap/LeafletMapMarkerShapeField'
import ShapeReferenceInput from '../../components/ShapeReferenceInput'
import {maxLength, required} from '../../lib/common-validators'

export const RegionZoneCreate = props => (
  <Create {...props} title="Create Region Zone">
    <SimpleForm>
      <HeaderWithPreviousButton title="Region Zone Creation" />
      <TextInput
        source="title"
        validate={[required, maxLength(255)]}
        fullWidth={true}
      />
      <ShapeReferenceInput isCreationAllowed={true} isSelectDisabled={false} />
      <LeafletMapMarkerShapeField />
    </SimpleForm>
  </Create>
)
