import React from 'react'
import {
  List,
  Datagrid,
  DateField,
  TextField,
  EditButton,
  DeleteButton,
  Filter,
  TextInput,
} from 'react-admin'
import {ListActions} from '../../components/ListActions'

const ShapeFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by shape name" source="name[$like]" alwaysOn />
  </Filter>
)

export const ShapeList = props => (
  <List
    {...props}
    title="Shape List"
    actions={<ListActions />}
    bulkActionButtons={false}
    filters={<ShapeFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)
