import {Box} from '@material-ui/core'
import React from 'react'
import {Edit, SimpleForm, TextInput} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import ShapeField from '../../components/ShapeField'
import {maxLength, required} from '../../lib/common-validators'

const ShapeName = ({record}) => <Box m={0}>{record ? record.name : ''}</Box>

export const ShapeEdit = props => {
  return (
    <Edit {...props} undoable={false} redirect={false} title={<ShapeName />}>
      <SimpleForm>
        <HeaderWithPreviousButton title="Shape Edition" />
        <TextInput
          source="name"
          label="Name"
          validate={[required, maxLength(255)]}
          fullWidth={true}
        />
        <ShapeField source="coords" />
      </SimpleForm>
    </Edit>
  )
}
