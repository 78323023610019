import React from 'react'
import {Create, SimpleForm, TextInput} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import LeafletMapMarkerField from '../../components/LeafletMap/LeafletMapMarkerField'
import {maxLength, required} from '../../lib/common-validators'

export const VhfCreate = props => (
  <Create {...props} title="Create VHF Channel">
    <SimpleForm>
      <HeaderWithPreviousButton title="VHF Creation" />
      <TextInput
        source="title"
        validate={[required, maxLength(255)]}
        fullWidth={true}
      />
      <TextInput
        source="description"
        label="Description"
        validate={required}
        multiline={true}
        fullWidth={true}
      />
      <LeafletMapMarkerField />
    </SimpleForm>
  </Create>
)
