import {useRef} from 'react'
import {validateGeoJSON} from '../utils/helpers'

const useValidateGeoJSON = geojson => {
  const ref = useRef(null)
  const errors = useRef([])

  const {errors: formatErrors} = validateGeoJSON(geojson)
  errors.current = formatErrors

  if (errors.current.length === 0) {
    ref.current = geojson
  }

  return {GeoJSON: ref.current, errors: errors.current}
}

export default useValidateGeoJSON
