import {Box, CircularProgress, LinearProgress} from '@material-ui/core'
import React from 'react'
import {Flex} from './Layout'

const LoaderOverlay = ({isVisible}) => {
  if (!isVisible) {
    return null
  }

  return (
    <Box
      position="absolute"
      left={0}
      top={0}
      zIndex={9999}
      width="100%"
      height="100%">
      <LinearProgress />
      <Box
        background="#fff"
        width="100%"
        height="100%"
        opacity=".4"
        position="absolute"
      />
      <Flex height="100%">
        <CircularProgress size={100} />
      </Flex>
    </Box>
  )
}

export default LoaderOverlay
