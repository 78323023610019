import React from 'react'
import {Edit, SimpleForm} from 'react-admin'
import {RichTextInput} from './RichTextInput'

export const PageBlockRichTextInput = ({record, location, basePath}) => {
  return (
    <Edit
      undoable={false}
      resource="page-block"
      record={record}
      location={location}
      basePath={basePath}
      id={record.id}
      title={<></>}>
      <SimpleForm redirect={false}>
        <RichTextInput source="data" />
      </SimpleForm>
    </Edit>
  )
}
