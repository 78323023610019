import React from 'react'
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import {RichTextInput} from '../../components/RichTextInput'
import {maxLength, required} from '../../lib/common-validators'

export const BylawCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <HeaderWithPreviousButton title="Bylaw Creation" />
      <TextInput
        source="title"
        label="Title"
        fullWidth={true}
        validate={[required, maxLength(255)]}
      />
      <RichTextInput source="richtext_description" />
      <ReferenceInput
        label="Region"
        source="region_id"
        reference="region-zone"
        validate={required}>
        <SelectInput optionText="title" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)
