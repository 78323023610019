import {Typography} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React from 'react'
import {Button} from 'react-admin'
import {useHistory} from 'react-router-dom'
import {useTypographyStyles} from '../hooks/useTypographyStyles'
import {Flex} from './Layout'

const HeaderWithPreviousButton = ({title, isPreviousVisible = true}) => {
  const history = useHistory()
  const {heading} = useTypographyStyles()

  const onBack = () => {
    history.goBack()
  }

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      mb={1}>
      <Typography margin={0} variant="h5" className={heading}>
        {title}
      </Typography>
      {isPreviousVisible && (
        <Button onClick={onBack} label="Back">
          <ArrowBack />
        </Button>
      )}
    </Flex>
  )
}

export default HeaderWithPreviousButton
