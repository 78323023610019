import {Box, Link, Typography} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Button} from 'react-admin'
import {useFormState} from 'react-final-form'
import {useBoolean} from 'react-use'
import {useTypographyStyles} from '../../hooks/useTypographyStyles'
import useValidateGeoJSON from '../../hooks/useValidateGeoJSON'
import {cleanGeoJSON} from '../../utils/helpers'
import {Flex} from '../Layout'
import Portal from '../Portal'
import LeafletMap from './LeafletMap'
import SimplifyTool from './SimplifyTool'

const LeafletMapContainer = ({record, source}) => {
  const {values} = useFormState()
  const isGeoJSONCleaned = useRef(false)
  const [isOpenDialog, toggleIsOpenDialog] = useBoolean(false)

  const data = values[source] ? values[source] : record[source]

  const {
    palette: {
      error: {main: errorColor},
    },
  } = useTheme()
  const {errorLink} = useTypographyStyles()

  const geojson = useMemo(() => {
    try {
      let result = data
      if (result && !isGeoJSONCleaned.current) {
        result = cleanGeoJSON(result)
        isGeoJSONCleaned.current = true
      }
      return result ? JSON.parse(result) : null
    } catch (error) {
      console.info('Not a valid JSON format to draw a shape :', error.message)
    }
  }, [data])

  const {GeoJSON, errors} = useValidateGeoJSON(geojson)

  return (
    <Box position="relative" width="60%">
      {errors.length > 0 && isGeoJSONCleaned.current && (
        <>
          <Box
            position="absolute"
            zIndex={999}
            color={errorColor}
            left={15}
            bottom={15}>
            <Typography m={0} mb={1}>
              Invalid GeoJSON format, shape might not appear.
            </Typography>
            <Link mt={1} onClick={toggleIsOpenDialog} className={errorLink}>
              View errors details
            </Link>
          </Box>

          <ErrorDialog
            isOpen={isOpenDialog}
            errors={errors}
            onClose={toggleIsOpenDialog}
          />
        </>
      )}

      <Box width="100%" height="100%">
        <LeafletMap GeoJSON={GeoJSON} />
        {GeoJSON && errors.length === 0 && (
          <SimplifyTool geojson={GeoJSON} source={source} />
        )}
      </Box>
    </Box>
  )
}

export default LeafletMapContainer

const ErrorDialog = ({errors, isOpen = false, onClose}) => {
  const [isOpenDialog, setIsOpenDialog] = useState(isOpen)
  const {
    palette: {grey},
  } = useTheme()

  useEffect(() => {
    setIsOpenDialog(isOpen)
  }, [isOpen, isOpenDialog])

  const onCloseDialog = () => {
    setIsOpenDialog(false)
    onClose()
  }

  if (!isOpenDialog) {
    return null
  }

  return (
    <Portal>
      <Flex position="fixed" width="100vw" height="100vh" top={0} left={0}>
        <Box bgcolor="#fff" borderRadius={8} maxWidth="50%" zIndex={1}>
          <Box pb={3} pt={3} px={4}>
            <Typography color="error" variant="h6">
              Invalid GeoJSON format
            </Typography>
            <Box mt={2}>
              {errors.map((error, index) => (
                <Typography key={index}>- {error.message}</Typography>
              ))}
            </Box>
          </Box>

          <Flex
            justifyContent="end"
            borderTop={1}
            borderColor={grey[300]}
            py={2}
            px={3}>
            <Button label="Close" onClick={onCloseDialog} />
          </Flex>
        </Box>
        <Box
          onClick={onCloseDialog}
          bgcolor="rgba(0,0,0,0.4)"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top={0}
          zIndex={0}
        />
      </Flex>
    </Portal>
  )
}
