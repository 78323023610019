import {createMuiTheme} from '@material-ui/core/styles'
import {defaultTheme} from 'react-admin'

export const theme = createMuiTheme({
  ...defaultTheme,
  ...{
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#00B2A9',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
        contrastText: '#ffffff',
      },
      secondary: {
        light: '#0066ff',
        main: '#00B2A9',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#ffffff',
      },
      // error: will use the default color
    },
  },
})
