import React from 'react'
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import LeafletMapMarkerShapeField from '../../components/LeafletMap/LeafletMapMarkerShapeField'
import ShapeReferenceInput from '../../components/ShapeReferenceInput'
import {maxLength, required} from '../../lib/common-validators'

export const BylawAreasCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <HeaderWithPreviousButton title="Bylaw Area Creation" />
      <TextInput
        source="title"
        label="Title"
        validate={[required, maxLength(255)]}
        fullWidth={true}
      />
      <TextInput
        source="description"
        label="Description"
        validate={required}
        fullWidth={true}
        multiline={true}
      />
      <ReferenceInput
        label="Region"
        source="region_id"
        reference="region-zone"
        validate={required}>
        <SelectInput optionText="title" />
      </ReferenceInput>
      <ShapeReferenceInput isCreationAllowed={true} isSelectDisabled={false} />
      <LeafletMapMarkerShapeField />
    </SimpleForm>
  </Create>
)
