import {Box, Button, IconButton, Slider} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'
import Add from '@material-ui/icons/AddCircleOutline'
import Cancel from '@material-ui/icons/Cancel'
import Check from '@material-ui/icons/Check'
import LinearScale from '@material-ui/icons/LinearScale'
import Remove from '@material-ui/icons/RemoveCircleOutline'
import simplify from '@turf/simplify'
import 'leaflet/dist/leaflet.css'
import {round} from 'lodash'
import React, {useContext, useRef, useState} from 'react'
import {useForm} from 'react-final-form'
import {useDebounce, useUpdateEffect} from 'react-use'
import {useButtonStyles} from '../../hooks/useButtonStyles'
import {Flex} from '../Layout'
import {ShapeContext} from '../ShapeField'

const TOLERANCE_MIN = 0
const TOLERANCE_MAX = 0.01
const TOLERANCE_STEP = 0.0001

const SimplifyTool = React.memo(({geojson, source}) => {
  const form = useForm()
  const {leafletButton} = useButtonStyles()
  const {
    palette: {grey},
  } = useTheme()

  const {isSimplifyEnabled, toggleIsSimplifyEnabled} = useContext(ShapeContext)

  const [tolerance, setTolerance] = useState(0)
  const [debouncedTolerance, setDebouncedTolerance] = useState(tolerance)

  const preservedData = useRef(geojson)

  useDebounce(
    () => {
      setDebouncedTolerance(tolerance)
    },
    300,
    [tolerance],
  )

  useUpdateEffect(() => {
    const updatedShape = simplify(preservedData.current, {
      tolerance,
      highQuality: true,
    })
    form.change(source, JSON.stringify(updatedShape))
  }, [debouncedTolerance])

  const onChange = (_event, updatedTolerance) => {
    setTolerance(updatedTolerance)
  }

  const increaseTolerance = () => {
    if (tolerance < TOLERANCE_MAX) {
      setTolerance(oldValue => round(oldValue + TOLERANCE_STEP, 4))
    }
  }

  const decreaseTolerance = () => {
    if (tolerance > TOLERANCE_MIN) {
      setTolerance(oldValue => round(oldValue - TOLERANCE_STEP, 4))
    }
  }

  const onReset = () => {
    setTolerance(0)
    form.change(source, JSON.stringify(preservedData.current))

    setTimeout(() => {
      toggleIsSimplifyEnabled()
    }, 1)
  }

  const onSave = () => {
    preservedData.current = geojson
    setTolerance(0)
    toggleIsSimplifyEnabled()
  }

  return (
    <Flex
      position="absolute"
      zIndex={9998}
      width="100%"
      bottom={8}
      left={12}
      justifyContent="start">
      <Flex width="60%" justifyContent="start">
        {!isSimplifyEnabled && (
          <Button
            variant="outlined"
            startIcon={<LinearScale />}
            className={leafletButton}
            onClick={toggleIsSimplifyEnabled}>
            Simplify
          </Button>
        )}

        {isSimplifyEnabled && (
          <Flex
            width="100%"
            bgcolor="#fff"
            borderRadius={4}
            px={1}
            border={1}
            borderColor={grey[400]}>
            <IconButton
              aria-label="confirm"
              size="small"
              onClick={decreaseTolerance}>
              <Remove />
            </IconButton>
            <Box mx={2} width="100%" mt={0.5}>
              <Slider
                value={tolerance}
                step={TOLERANCE_STEP}
                min={TOLERANCE_MIN}
                max={TOLERANCE_MAX}
                size="large"
                onChange={onChange}
              />
            </Box>
            <Flex>
              <IconButton
                aria-label="Increase"
                size="small"
                onClick={increaseTolerance}>
                <Add />
              </IconButton>

              <Flex>
                <Box width="1px" bgcolor={grey[200]} height="35px" mx={2} />
                <Box mr={2}>
                  <IconButton aria-label="reset" size="small" onClick={onReset}>
                    <Cancel />
                  </IconButton>
                </Box>
                <IconButton aria-label="confirm" size="small" onClick={onSave}>
                  <Check />
                </IconButton>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
})

export default React.memo(SimplifyTool)
