import FormHelperText from '@material-ui/core/FormHelperText'
import {useTheme} from '@material-ui/core/styles'
import 'ace-builds'
import 'ace-builds/webpack-resolver'
import React from 'react'

const FieldErrors = ({meta}) => {
  const {
    palette: {
      error: {main: errorColor},
    },
  } = useTheme()

  if (meta.error && meta.touched) {
    return (
      <FormHelperText style={{color: errorColor}}>{meta.error}</FormHelperText>
    )
  }
  return null
}

export default FieldErrors
