import {useTheme} from '@material-ui/core/styles'
import {Icon} from 'leaflet'
import 'leaflet/dist/leaflet.css'
import {nanoid} from 'nanoid'
import React, {useLayoutEffect, useMemo, useRef} from 'react'
import {GeoJSON, MapContainer, Marker, TileLayer, useMap} from 'react-leaflet'
import markerIconSvg from '../../assets/marker.svg'

const markerIcon = new Icon({
  iconUrl: markerIconSvg,
  iconRetinaUrl: markerIconSvg,
  iconSize: [50, 50],
  iconAnchor: [25, 50],
})

const Shape = React.memo(({geojson}) => {
  const map = useMap()
  const geoRef = useRef(null)

  useLayoutEffect(() => {
    if (geoRef.current.getBounds()?._northEast) {
      map.fitBounds(geoRef.current.getBounds())
    }
  })

  const eventHandlers = useMemo(
    () => ({
      click() {
        map.fitBounds(geoRef.current.getBounds())
      },
    }),
    [map],
  )

  return (
    <GeoJSON
      key={nanoid()}
      data={geojson}
      eventHandlers={eventHandlers}
      ref={geoRef}
    />
  )
})

const UpdateMapCenter = ({center}) => {
  const map = useMap()
  map.setView(center)
  return null
}

const LeafletMap = ({
  GeoJSON,
  center = [-41.25955664629741, 174.49498245083177],
  zoom = 5,
  marker,
}) => {
  const {
    palette: {grey},
  } = useTheme()

  return (
    <MapContainer
      center={center}
      zoom={zoom}
      scrollWheelZoom={false}
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        borderStyle: 'solid',
        borderColor: grey[300],
        borderWidth: 1,
      }}>
      <UpdateMapCenter center={center} />

      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {marker && <Marker position={marker} icon={markerIcon} />}
      {GeoJSON && <Shape geojson={GeoJSON} />}
    </MapContainer>
  )
}

export default React.memo(LeafletMap)
