import {Typography} from '@material-ui/core'
import React from 'react'
import {Create, FormTab, TabbedForm, TextInput} from 'react-admin'
import HeaderWithPreviousButton from '../../components/HeaderWithPreviousButton'
import LeafletMapMarkerField from '../../components/LeafletMap/LeafletMapMarkerField'
import {useTypographyStyles} from '../../hooks/useTypographyStyles'
import {maxLength, required} from '../../lib/common-validators'

export const BoatRampCreate = props => {
  const {caption} = useTypographyStyles()

  return (
    <Create {...props} title="Create Boat Ramp">
      <TabbedForm>
        <FormTab label="Boat Ramp">
          <HeaderWithPreviousButton title="Boat Ramp Creation" />
          <TextInput
            source="title"
            validate={[required, maxLength(255)]}
            fullWidth={true}
          />
          <TextInput
            source="description"
            label="Description"
            validate={required}
            multiline={true}
            fullWidth={true}
          />
          <LeafletMapMarkerField />
        </FormTab>
        <FormTab label="Gallery">
          <Typography className={caption}>
            Gallery upload will be available after saving.
          </Typography>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}
